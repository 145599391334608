import * as React from 'react';
import { useState, useEffect} from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormControl, Input } from '@mui/material'; 
import SchoolInput from './SchoolInput';
import AcceptanceInput from './AcceptanceInput';
import RejectionInput from './RejectionInput';
import WaitlistInput from './WaitlistInput';

export default function Decisions(props) {
    const [school, setSchool] = useState("");
    const [acceptances, setAcceptances] = useState([]);
    const [rejections, setRejections] = useState([]);
    const [waitlist, setWaitlist] = useState([]);

    const handleSchoolChange = (newValue) => {
      props.school(newValue);
    };

    useEffect(() => {
      props.acceptances(acceptances);
    }, [acceptances])
    useEffect(() => {
      props.rejections(acceptances);
    }, [rejections])
    useEffect(() => {
      props.waitlist(waitlist);
    }, [waitlist])
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Background Info
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <SchoolInput school={handleSchoolChange}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <AcceptanceInput acceptances={setAcceptances}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <RejectionInput rejections={setRejections}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <WaitlistInput waitlist={setWaitlist}/>
        </Grid>'
        
      </Grid>
    </React.Fragment>
  );
}





