import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { UserAuth } from '../context/AuthContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { db } from '../Firebase/firebase';
import {
  collection,
  addDoc,
  deleteDoc,
  setDoc,
  doc, 
  updateDoc,      
  getDoc,
  getDocs
} from 'firebase/firestore';
import FirebaseUpload from './FirebaseImage';
import General from './AboutMe/Index';
import Advisor from './Advisor/Index';
import Academics from './Academics/Index';
import Decisions from './Decisions/Index';
import ActtivityForm from '../Edit Form/Activities';
import AwardForm from './Awards/index'
import EssayForm from './Essays';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SchoolArray } from './schools';

function Copyright() { 
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Nerd Apply LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Submit() {
  const { user, profileData } = UserAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [newFirstName, setNewFirstName] = useState('');
  const [major, setMajor] = useState([]);
  const [gpaw, setGPAW] = useState('');
  const [race, setRace] = useState('');
  const [gender, setGender] = useState('');
  const [hooks, setHooks] = useState('');
  const [gpauw, setGPAUW] = useState('');
  const [school, setSchool] = useState('');
  const [SAT, setSAT] = useState(false);
  const [testOptional, setTestOptional] = useState();
  const [ACT, setACT] = useState(false);
  const [bio, setBio] = useState('');
  const [state, setState] = useState('');
  const [image, setImage] = useState('');
  const [acceptances, setAcceptances] = useState([]);
  const [rejections, setRejections] = useState([]);
  const [waitlist, setWaitlist] = useState([]);
  const [advisor, setAdvisor] = useState('');
  const [show, setShow] = useState('');
  const [reccomend, setReccomend] = useState('');
  const [advisorName, setAdvisorName] = useState('');
  const [advisorEmail, setAdvisorEmail] = useState('');
  const [advisorCompany, setAdvisorCompany] = useState();
  const [formData, setFormData] = useState({ activities: [], titles: [], categories: [], positions: [] });
  const [awardData, setAwardData] = useState({ levels: [], titles: [] });  const [title, setTitle] = useState(['']);
  const [essayData, setEssayData] = useState({ schools: [], prompts: [], essays: []});
  const [category, setCategory] = useState(['']);
  const [schoolEssay, setSchoolEssay] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [essays, setEssays] = useState([]);
  const [position, setPosition] = useState([]);
  const navigate = useNavigate();

  const steps = ['Background Info', 'Academics', 'Decisions', 'Advisor', 'Awards', 'Activities', 'Essays'];


  // Function to create a new user document in the database
  const createUser = async () => {
    // const usersCollectionRef = doc(db, "profiles", user.uid);
    // try {
    //   const docRef = await setDoc(usersCollectionRef, {
    //     Name: newFirstName,
    //     Bio: bio,
    //     Major: major,
    //     Gender: gender,
    //     Race: race,
    //     SAT: SAT,
    //     ACT: ACT,
    //     GPAW: gpaw,
    //     GPAUW: gpauw,
    //     Residence: state,
    //     School: school,
    //     Waitlist: waitlist,
    //     Rejections: rejections,
    //     Acceptances: acceptances,
    //     ProfileImg: image,
    //     Hooks: hooks,
    //     Advisor: advisorName,

    //   });
    //   console.log(docRef)
    //   let subDocRef;

    //   // Create sub-collections for activities and awards
    //   createActivity(usersCollectionRef.id);
    //   createAward(usersCollectionRef.id);

    //    //add a new sub collection with essay data
    //    const essayCollectionRef = collection(usersCollectionRef, "Essays");
    //    for (let i = 0; i < essays.length; i++) {
    //      const data = {
    //        School: schoolEssay[i], 
    //        Prompt: prompts[i], 
    //        Essays: essays[i]
    //      };
 
    //      subDocRef = await addDoc(essayCollectionRef, data)
    //      .then((usersCollectionRef) => {
    //        console.log("Document written with ID: ", usersCollectionRef.id);
    //      })
    //      .catch((error) => {
    //        console.error("Error adding document: ", error);
    //      });
    //    }

    //   // Create sub-collection for advisor information
    //   const AdvisorSubCollectionRef = collection(doc(db, 'profiles', usersCollectionRef.id), 'Advisor');
    //   let AdvisorSubDocRef;
    //   if (show) {
    //     // Add advisor information if provided
    //     AdvisorSubDocRef = await addDoc(AdvisorSubCollectionRef, {
    //       Advisor: advisor,
    //       Name: advisorName,
    //       Email: advisorEmail,
    //       Company: advisorCompany,
    //       Reccomend: reccomend
    //     });
    //   } else {
    //     // Add only advisor name if not provided
    //     AdvisorSubDocRef = await addDoc(AdvisorSubCollectionRef, {
    //       Advisor: advisor
    //     });
    //   }

    //   console.log('Document with ID:', usersCollectionRef.id, 'and sub-document with ID:', AdvisorSubDocRef.id, 'created successfully!');
    // } catch (error) {
    //   console.error('Error adding document:', error);
    // }
  };


  

    const createActivity = async (id) => {
      const activitiesData = formData.activities.map((activity, index) => ({
        activity: activity,
        title: formData.titles[index],
        category: formData.categories[index],
        position: formData.positions[index],
      }));

      const activitiesCollectionRef = collection(doc(db, 'profiles', id), 'Activities');
      const querySnapshot = await getDocs(activitiesCollectionRef); 

      // Create or update documents
      for (let i = 0; i < activitiesData.length; i++) {
        if (i < querySnapshot.docs.length) {
          // If document exists, update it
          const docId = querySnapshot.docs[i].id;
          const activityRef = doc(activitiesCollectionRef, docId);
          await updateDoc(activityRef, activitiesData[i]);
        } else {
          // If document does not exist, create it
          await addDoc(activitiesCollectionRef, activitiesData[i]);
        }
      }

      // Delete any remaining documents in Firestore that were not in activitiesData
      for (let i = activitiesData.length; i < querySnapshot.docs.length; i++) {
        const docId = querySnapshot.docs[i].id;
        const activityRef = doc(activitiesCollectionRef, docId);
        await deleteDoc(activityRef);
      }

      return null;
    };


  const createEssay = async (id) => {
    console.log(essayData);
    const essayFormData = essayData.essays.map((essay, index) => ({
      Essay: essayData.essays[index],
      Prompt: essayData.prompts[index],
      School: essayData.schools[index],
    }));
    console.log(essayFormData);
    let subDocRef;
    const essayCollectionRef = collection(doc(db, 'profiles', id), 'Essays');
    const querySnapshot = await getDocs(essayCollectionRef); 

    // Create or update documents
    for (let i = 0; i < essayFormData.length; i++) {
      console.log(i);
      if (i < querySnapshot.docs.length) {
        // If document exists, update it
        const docId = querySnapshot.docs[i].id;
        const essayRef = doc(essayCollectionRef, docId);
        await updateDoc(essayRef, essayFormData[i]);
      } else {
        // If document does not exist, create it
        await addDoc(essayCollectionRef, essayFormData[i]);
      }
    }

    // Delete any remaining documents in Firestore that were not in activitiesData
    for (let i = essayFormData.length; i < querySnapshot.docs.length; i++) {
      const docId = querySnapshot.docs[i].id;
      const essayRef = doc(essayCollectionRef, docId);
      await deleteDoc(essayRef);
    }

    return null;

      }
  // Function to create the awards sub-collection for a user
  const createAward = async (id) => {
    const awardsData = awardData.levels.map((level, index) => ({
      title: awardData.titles[index],
      level: level,
    }));

    const awardsCollectionRef = collection(doc(db, 'profiles', id), 'Awards');
    for (let i = 0; i < awardsData.length; i++) {
      const querySnapshot = await getDocs(awardsCollectionRef); 
      if (!querySnapshot.empty) {
        const firstDocument = querySnapshot.docs[i];
        const firstDocumentId = firstDocument.id;
        const awardRef = doc(collection(doc(db, 'profiles', user.uid), 'Awards'), firstDocumentId);
        await updateDoc(awardRef, awardsData[i]);
      }
      else {
        await addDoc(awardsCollectionRef, awardsData[i]);
      }
      return null; // Return null if the collection is empty

  }


    // Call the function to get the first document ID
   
  };

  // Function to upload images for colleges
  const FirebaseUpdate = async () => {
    try {
      const userRef = doc(db, 'profiles', user.uid);
      await updateDoc(userRef, {
        Name: "beth",
        HomieScor: "100"
      });

      console.log(profileData);
    } catch (e) {
      console.log(e.message);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <div><FirebaseUpload ProfileImg={setImage} /><General name={setNewFirstName} Bio={setBio} state={setState} gender={setGender} race={setRace} hooks={setHooks} /></div>;
      case 1:
        return <Academics major={setMajor} gpaw={setGPAW} gpauw={setGPAUW} testOptional={setTestOptional} sat={setSAT} act={setACT} />;
      case 2:
        return <Decisions rejections={setRejections} waitlist={setWaitlist} acceptances={setAcceptances} school={setSchool} />;
      case 3:
        return <Advisor show={setShow} AdvisorName={setAdvisorName} AdvisorEmail={setAdvisorEmail} AdvisorCompany={setAdvisorCompany} advisor={setAdvisor} reccomend={setReccomend} />;
      case 4:
        return <AwardForm awardData={setAwardData} />;
      case 5:
        return <ActtivityForm formData={setFormData} />;
      case 6:
        return <EssayForm formData={setEssayData}/>;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = async () => {
    const userRef = doc(db, 'profiles', user.uid);
    if (activeStep === 0) {
      console.log(newFirstName);
      console.log(bio);
      try {
        await updateDoc(userRef, {
          ProfileImg: image,
          Name: newFirstName,
          Bio: bio,
          Gender: gender,
          Race: race,
          Residence: state,
          Hooks: hooks,
  
        });
        console.log(profileData);
      } catch (e) {
        console.log(e.message);
      }
    } else if (activeStep === 1) {
      console.log(gpaw);
      console.log(gpauw);
      console.log(major);
      await updateDoc(userRef, {
        major: major,
        GPAW: gpaw,
        GPAUW: gpauw,
        testOptional: testOptional,
        SAT: SAT,
        ACT: ACT
        // Add other fields to update here
      });
     

    } else if (activeStep === 2) {
      await updateDoc(userRef, {
        rejections: rejections,
        waitlist: waitlist,
        acceptances: acceptances,
        School: school,
        // Add other fields to update here
      });
    
    } else if (activeStep === 3) {
      const AdvisorSubCollectionRef = collection(doc(db, 'profiles', user.uid), 'Advisor');

      // Retrieve the first document in the Advisor collection
      const getFirstAdvisorDocumentId = async () => {
        const querySnapshot = await getDocs(AdvisorSubCollectionRef);
        if (!querySnapshot.empty) {
          const firstDocument = querySnapshot.docs[0];
          const firstDocumentId = firstDocument.id;
          return firstDocumentId;
        }
        return null; // Return null if the collection is empty
      };

      // Call the function to get the first document ID
      const firstDocumentId = await getFirstAdvisorDocumentId();
      const advisorRef = doc(collection(doc(db, 'profiles', user.uid), 'Advisor'), firstDocumentId);
      
      await updateDoc(advisorRef, {
        advisorName: advisorName,
        advisorEmail: advisorEmail,
        advisorCompany: advisorCompany,
        // Add other fields to update here
      });
      
    
    } else if (activeStep === 4) {
      
      createAward(user.uid);

    } else if (activeStep === 5) {
      createActivity(user.uid);

    } else if (activeStep === 6) {
      createEssay(user.uid);
      
      // Handle step 6
      // Add your code for step 6 logic here
      // For example, update the document with the new data
    }
  
    setActiveStep(activeStep + 1);
  };


  const makeUser = () => {
    try {
      createUser();
    } catch (error) {
      console.error('Error adding document:', error);
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // Function to upload images for colleges
  const uploadImages = async () => {
    for (let i = 0; i < 98; i++) {
      try {
        console.log(SchoolArray[i] + ' num:' + i);
        const docRef = await setDoc(doc(db, 'CollegeImages', SchoolArray[i]), { Name: SchoolArray[i], Img: `https://firebasestorage.googleapis.com/v0/b/collegeadmit-auth.appspot.com/o/CollegeLogos%2F${SchoolArray[i]}.png?alt=media&token=fa90be7f-193e-4fa7-8b22-ce9260a9823c` });
      } catch (error) {
        console.error('Error adding document:', error);
      }
    }
  };


  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container component="main" maxWidth="md" sx={{ mb: 8 }} >
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Button onClick={uploadImages}>upload</Button>
            <Typography component="h1" variant="h4" align="center">
              Application Info
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, display: { xs: 'none', sm: 'flex' } }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, display: { xs: 'flex', sm: 'none' } }}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for submitting your profile!
                </Typography>
                <Typography variant="subtitle1">
                  Preview your profile here. You can edit your profile any time in the user profile section.
                </Typography>
                <Button onClick={() => navigate("/premiumprofiles/"+user.uid)} >Preview</Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? 'Submit Profile' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Paper>
          <Copyright />
        </Container>
      </ThemeProvider>
    </div>
  );
}
