import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function PersonalInfo(props) {
  return (
    <Card sx={{ maxWidth: {xs: 290, sm: 300, md: 400}, display: 'flex', flexDirection: 'column' }} className="InfoCard">
      <CardMedia
        sx={{ height: "30vh", paddingTop: '56.25%' }}
        image={props.img}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.name}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {props.bio}
        </Typography>
      </CardContent>
    </Card>
  );
}