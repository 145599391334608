import {React, useState, useEffect, Fragment} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FormControl} from '@mui/material'; 
import AdvisorInput from './AdvisorInput';
import AdvisorReccomendInput from './AdvisorReccomendInput';

export default function Advisor(props) {
    const [advisor, setAdvisor] = useState();
    const [show, setShow] = useState(false);
    const [reccomend, setReccomend] = useState();


    useEffect(() => {
        props.advisor(advisor);
      }, [advisor])
      useEffect(() => {
        props.show(show);
      }, [show])
    useEffect(() => {
        props.reccomend(reccomend);
      }, [reccomend])

    
  return (
    <FormControl>
      <Typography variant="h6" gutterBottom>
        College Consultant/Advisor
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h7" gutterBottom>
        Did you use a college consultant or advisor?
      </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <AdvisorInput advisor={setAdvisor} show={setShow}/>
        </Grid>
        {show ?  
        <Grid item xs={12} sm={12}>
        <Typography variant="h7" gutterBottom>
        Would you reccomend them to others?
         </Typography>
        </Grid>:
        null
        }
        {show ?  
        <Grid item xs={12} sm={6}>
        <AdvisorReccomendInput reccomend={setReccomend}/>
      </Grid>:
      null
        }
        {show ?  
        <Grid item xs={12} sm={12}>
        <TextField
          required
          id="nameame"
          name="nameame"
          label="Advisor/Consultant Name"
          onChange={(e) => {
              props.AdvisorName(e.target.value);
            }}
          fullWidth
          autoComplete="given-name"
          variant="standard"
        />
        </Grid>:
         null
        }
        {show ?  
        <Grid item xs={12} sm={12}>
        <TextField
          id="Email"
          name="Eamil"
          label="Advisor/Consultant Email"
          onChange={(e) => {
              props.AdvisorEmail(e.target.value);
            }}
          fullWidth
          autoComplete="email"
          variant="standard"
        />
        </Grid>:
         null
        }
        {show ?  
        <Grid item xs={12} sm={12}>
        <TextField
          id="Consulting Company"
          name="Company"
          label="Advisor/Consultant Company"
          onChange={(e) => {
              props.AdvisorCompany(e.target.value);
            }}
          fullWidth
          autoComplete="company"
          variant="standard"
        />
        </Grid>:
         null
        }
      </Grid>
      </FormControl>
  );
}