import { React, useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { popularMajors } from './Majors';
import { UserAuth } from '../../context/AuthContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MajorInput(props) {
  const [value, setValue] = useState([]);
  const { profileData } = UserAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (profileData) {
        console.log(profileData.Major);
        setValue(profileData.Major);
        props.major(profileData.Major);
      }
    };

    fetchData();
  }, [profileData]);

  const handleOnChange = (event, newValue) => {
    setValue(newValue);
    props.major(newValue);
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      value={value}
      fullWidth
      disableCloseOnSelect
      onChange={handleOnChange}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Academic Interests" placeholder="Academic Interests" />
      )}
    />
  );
}

const options = popularMajors;
