import * as React from 'react';
import { useState, useEffect} from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FormControl, Container} from '@mui/material'; 
import MajorInput from './MajorInput';
import GpawInput from './GpawInput';
import GpauwInput from './GpauwInput';
import { UserAuth } from '../../context/AuthContext';

export default function Academics(props) {
    const { profileData } = UserAuth();
    const [major, setMajor] = useState("");
    const [testOptional, setTestOptional] = useState(true);
    const [sat, setSat] = useState();
    const [act, setAct] = useState();
    const [gpaw, setGPAW] = useState();
    const [gpauw, setGPAUW] = useState("");

    const handleGpauwChange = (newValue) => {
      props.gpauw(newValue);
    };
    const handleGpawChange = (newValue) => {
      console.log(gpaw);
      props.gpaw(newValue);
    };
    const handleTestOptionalChange = (newValue) => {
      props.testOptional(newValue.testOptional);
      setSat(newValue.sat);
      setAct(newValue.act);
      props.sat(newValue.sat);
      props.act(newValue.act);
    };


    useEffect(() => {
      console.log(gpaw)
      
    }, [])

    useEffect(() => {
      props.testOptional(testOptional);
      props.sat(sat);
      props.act(act);
    }, [testOptional])

    useEffect(() => {
      const fetchData = async () => {
        if (profileData) {
          setSat(profileData.SAT);
          setAct(profileData.ACT);
          props.sat(profileData.SAT);
          props.act(profileData.SAT);
          props.gpaw(profileData.GPAW);
          props.gpauw(profileData.GPAUW); 
          props.major(profileData.major);
        }
      };
      console.log("hello")
  
      fetchData();
    }, [profileData]);
    
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Background Info
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <MajorInput major={setMajor}/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <GpawInput gpaw={handleGpawChange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <GpauwInput GpaUW={handleGpauwChange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
          required
          id="ACT"
          name="ACT"
          label="ACT (optional)"
          value={act}
          onChange={(e) => {
              props.act(e.target.value);
              setAct(e.target.value);
            }}
          fullWidth
          autoComplete="ACT"
          variant="standard"
           />
         </Grid>
 
        <Grid item xs={12} sm={6}>
        <TextField
          required
          id="SAT"
          name="SAT"
          value={sat}
          label="SAT (optional)"
          onChange={(e) => {
              props.sat(e.target.value);
              setSat(e.target.value);
            }}
          fullWidth
          autoComplete="SAT"
          variant="standard"
        />
         </Grid> 
      </Grid>
       

    </React.Fragment>
  );
}





