import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UserAuth } from '../../context/AuthContext';
const options = ['White', 'Black', 'Lation', "Asian", 'Prefer Not Ro Say', "Other"];

export default function RaceInput(props) {
  const { profileData } = UserAuth();
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (profileData) {
        setValue(profileData.Race);
        props.race(profileData.Race);
      }
    };

    fetchData();
  }, [profileData]);



  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.race(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Race/Ethnicity" />}
      />
    </div>
  );
}