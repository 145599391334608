export const SchoolArray = [
  "Arizona State University",
"Auburn University",
"Baylor University",
"Boston University",
"Brigham Young University",
"Brown University",
"California Institute of Technology",
"Clemson University",
"Columbia University",
"Cornell University",
"Dartmouth College",
"Duke University",
"Emory University",
"Florida State University",
"Georgetown University",
"Georgia Institute of Technology",
"Harvard University",
"Indiana University Bloomington",
"Iowa State University",
"Johns Hopkins University",
"Louisiana State University",
"Massachusetts Institute of Technology",
"Michigan State University",
"New York University (NYU)",
"North Carolina State University",
"Northwestern University",
"Ohio State University",
"Pennsylvania State University",
"Princeton University",
"Purdue University",
"Rice University",
"Rutgers University",
"Stanford University",
"Syracuse University",
"Texas A&M University",
"University at Buffalo",
"University of Alabama",
"University of Arizona",
"University of California, Berkeley",
"University of California, Davis (UC Davis)",
"University of California, Irvine (UCI)",
"University of California, Los Angeles (UC Los Angeles)",
"University of California, Riverside",
"University of California, San Diego (UC San Diego)",
"University of California, Santa Barbara (UCSB)",
"University of California, Santa Cruz",
"University of Cincinnati",
"University of Colorado Boulder",
"University of Connecticut",
"University of Delaware",
"University of Florida",
"University of Georgia",
"University of Hawaii at Manoa",
"University of Idaho",
"University of Illinois at Urbana-Champaign (UIUC)",
"University of Iowa",
"University of Kansas",
"University of Kentucky",
"University of Louisville",
"University of Maryland, College Park",
"University of Massachusetts Amherst (UMass Amherst)",
"University of Memphis",
"University of Miami",
"University of Michigan",
"University of Minnesota Twin Cities",
"University of Mississippi",
"University of Missouri",
"University of Missouri-Kansas City",
"University of Nebraska-Lincoln",
"University of Nevada, Las Vegas",
"University of New Hampshire",
"University of New Mexico",
"University of North Carolina at Chapel Hill (UNC Chapel Hill)",
"University of Notre Dame",
"University of Oklahoma",
"University of Oregon",
"University of Pennsylvania",
"University of Pittsburgh",
"University of Rhode Island",
"University of South Carolina",
"University of South Florida",
"University of Southern California (USC)",
"University of Tennessee, Knoxville",
"University of Texas at Austin (UT Austin)",
"University of Texas at Dallas (UT Dallas)",
"University of Tulsa",
"University of Utah",
"University of Vermont",
"University of Virginia",
"University of Washington",
"University of Wisconsin-Madison",
"University of Wyoming",
"Vanderbilt University",
"Virginia Tech",
"Washington University in St. Louis",
"West Virginia University",
"Yale University"
];









