import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UserAuth } from '../../context/AuthContext';

export default function GpauwInput(props) {
  const { profileData } = UserAuth();
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (profileData) {
        setValue(profileData.GPAUW);
        props.GpaUW(profileData.GPAUW);
      }
    };
    console.log("hello")

    fetchData();
  }, [profileData]);

  return (
    <Autocomplete
      fullWidth
      onChange={(event, newValue) => {
        props.GpaUW(newValue);
        setValue(newValue);
      }}
      id="controllable-states-demo"
      options={options}
      value={value}
      getOptionLabel={(option) => option.toString()}
      renderOption={(props, option) => (
        <li {...props}>
          {option.toString()}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="GPA (UnWeighted)"
        />
      )}
    />
  );
}

const options = [4.0, 3.9, 3.8, 3.7, 3.5, 3.0, 2.0];
