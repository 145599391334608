import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SchoolArray } from '../schools';
import { UserAuth } from '../../context/AuthContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AcceptanceInput(props) {
  const [value, setValue] = useState([]);
  const { profileData } = UserAuth();

  useEffect(() => {
    if (profileData && profileData.Acceptances && profileData.Acceptances.length > 0) {
      setValue(profileData.Acceptances);
      let list=[];
      list.push(profileData.Acceptances);
      list.reduce((result, item, index) => {
        result[index] = item;
        return result;
      }, {});
      props.acceptances(list);
    }
  }, [profileData]);

  const handleOnChange = (event, newValue) => {
    let list=[];
      list.push(newValue);
      list.reduce((result, item, index) => {
        result[index] = item;
        return result;
      }, {});
    setValue(newValue);
    props.acceptances(list);
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={SchoolArray}
      disableCloseOnSelect
      onChange={handleOnChange}
      value={value}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      fullWidth
      renderInput={(params) => (
        <TextField {...params} label="Acceptances" placeholder="Acceptances" />
      )}
    />
  );
}
