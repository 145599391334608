import React from 'react';// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // eslint-disable-next-line
import Home from './HomePage';
import NavigationBar from './NavigationBar';
import SubCollectionpremium from './PremiumProfile/SubCollection';
import MUISignin from './UserLogin/MUISignin';
import MUISignup from './UserLogin/MUISignup';
import PremiumUserFormPage from './Premium Form';
import { AuthContextProvider } from './context/AuthContext';
import UserProfile from './UserAccount/Index';
import EditFormPage from './Edit Form/index';  
// import CheckoutSession from './stripe.js/CheckoutSession';





function App() {
  
  return (
    <div>
      <AuthContextProvider>
      <NavigationBar/>
    <Routes>
    <Route path="/" element={<MUISignup/>} />
    <Route path="/Home" element={<Home />} />
      <Route exact path="/premiumProfileUpload" element={<PremiumUserFormPage/>} />
      <Route exact path="/EditFormPage" element={<EditFormPage/>} />
      <Route exact path="/login" element={<MUISignin/>} />
      <Route exact path="/Account" element={<UserProfile/>} />
      <Route exact path="/signup" element={<MUISignup/>} /> 

      <Route exact path="/premiumProfiles/:id" element={<SubCollectionpremium/>} />
    </Routes>
    </AuthContextProvider>
    </div>
  );
}

export default App;


