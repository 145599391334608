import {React} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {states} from './States'


export default function  StateInput(props) {
  return (
    <Autocomplete
      fullWidth
      onChange={(event, newValue) => {
        props.state(newValue);
      }}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="State/Region"
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const options = states;