import {React} from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UserAuth } from '../../context/AuthContext';


export default function  YearInput(props) {
  const { profileData } = UserAuth();
  const [value, setValue] = useState(options[0]);

  useEffect(() => {
    const fetchData = async () => {
      if (profileData) {
        setValue(profileData.Year);
        props.year(profileData.Year);
      }
    };

    fetchData();
  }, [profileData]);
  return (
    <Autocomplete
      fullWidth
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        props.year(newValue);
      }}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Graduation Year"
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const options = Array.from({length: 19}, (_, index) => 2010 + index);;
