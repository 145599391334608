import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Typography, Select, MenuItem, InputLabel, Autocomplete } from "@mui/material";
import { UserAuth } from '../../context/AuthContext';
import { collection, getDocs, doc, runTransaction, snapshot } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';


export default function ActivityForm(props) {
  const { profileData, user } = UserAuth();
  const [activities, setActivities] = useState([]);



  
  const [formData, setFormData] = useState({
    activities: [],
    titles: [],
    categories: [],
    positions: [], 
  });
  useEffect(() => {
    addActivity();
  }, []);

  const addActivity = () => {
    setFormData({
      ...formData,
      activities: [...formData.activities, ""],
      titles: [...formData.titles, ""],
      categories: [...formData.categories, ""],
      positions: [...formData.positions, ""],
    });
    console.log(formData);
  };

  const SubCollectionData = async () => {
    const studentDataRef = collection(doc(db, "profiles", user.uid), "Activities");
  
    const activities = [];
    const titles = [];
    const categories = [];
    const positions = [];
  
    getDocs(studentDataRef)
      .then(response => {
        response.docs.forEach((doc) => {
          const data = doc.data(); // Retrieve the data from the document
  
          activities.push(data.activity);
          titles.push(data.title);
          categories.push(data.category);
          positions.push(data.position);
        });
  
        setFormData({activities, titles, categories, positions});
      })
      .catch(error => console.log(error.message));
  };
  useEffect(() => {
    SubCollectionData();
  }, [user.uid]);

  
  
    

  const deleteActivity = (index) => {
    const updatedData = { ...formData };
    updatedData.activities.splice(index, 1);
    updatedData.titles.splice(index, 1);
    updatedData.categories.splice(index, 1);
    updatedData.positions.splice(index, 1);
    setFormData(updatedData);
  };

  const handleInputChange = (event, index) => {
    console.log("80");
    const { name, value } = event.target || {};
    const updatedData = { ...formData };
    updatedData[name] = updatedData[name] || [];
    updatedData[name][index] = value;
    setFormData(updatedData);
    console.log(updatedData);
    props.formData(updatedData);
  };

  return (
    <div>
      {/* Loop over activities array */}
      {formData.activities.map((activity, index) => (
        <Grid container spacing={1} key={index}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Activity {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title"
              name="titles"
              label="Activity Title"
              fullWidth
              value={formData.titles[index]}
              onChange={(event) => handleInputChange(event, index)}
              autoComplete="given-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="position"
              name="positions"
              label="Position/Leadership Description"
              fullWidth
              value={formData.positions[index]}
              onChange={(event) => handleInputChange(event, index)}
              autoComplete="given-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <Select
              required
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              name="categories"
              value={formData.categories[index]}
              onChange={(event) => handleInputChange(event, index)}
              label="Categories"
              fullWidth
            >
              <MenuItem value="Work">Work</MenuItem>
              <MenuItem value="Voluneer">Volunteer</MenuItem>
              <MenuItem value="Student Council">Student Council</MenuItem>
              <MenuItem value="School">School</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select> */}
            <Autocomplete
              options={["Sports", "Student Council", "School", "Work", "Volunteer"]}
              value={formData.categories[index] || ""}
              onChange={(event, value) =>
                handleInputChange(
                  {
                    target: {
                      name: "categories",
                      value: value || ""
                    }
                  },
                  index
                )
              }
              renderInput={(params) => (
                <TextField {...params} label="Activity Category" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
             required
              id="activity"
              name="activities"
              key={index}
              fullWidth
              multiline
              rows={2}
              label={"Description (50 Characters or Less)"}
              value={formData.activities[index]} 
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
        </Grid>
      ))}
      <Button variant="contained" onClick={addActivity}>
        Add Activity
      </Button>
      <Button variant="contained" onClick={deleteActivity}>
        Delete Activity
      </Button>
    </div>
  );
}
