import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';
import "./Index.css"

export default function Background(props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
        <Grid container spacing={{ xs: 0, md: 0 }}>
          <Grid item xs={12} md={6}>
            <Typography > Race: </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight="bold">{props.race}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography > Gender: </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight="bold">{props.gender}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography > Hooks: </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography fontWeight="bold">{props.hooks}</Typography>
          </Grid>
          {/* will not show sat if sat is not equal to true */}
          {props.sat && (
                <>
                <Grid item xs={12} md={6}>
                    <Typography >School :</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography fontWeight="bold">{props.sat}</Typography>
                </Grid>
                </>
            )}
            {props.act && (
                <>
                <Grid item xs={12} md={6}>
                    <Typography >ACT:</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography fontWeight="bold">{props.act}</Typography>
                </Grid>
                </>
            )}

        </Grid>
      </Box>
    </div>
  );
}