import React from 'react'
import {Card, CardContent, Typography } from '@mui/material';

function ActvitiesSection(props) {
    return (
            <Card key={props.id} sx={{ height: '25vh' }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {props.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {props.category}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {props.Position}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {props.activity}
                    </Typography>
                  </CardContent>
            </Card>

    )
}

export default ActvitiesSection
