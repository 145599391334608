// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth,signOut, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC0smYDxez3Dak-EXAbHx1mb11mvOJVRrM",
  authDomain: "collegeadmit-auth.firebaseapp.com",
  projectId: "collegeadmit-auth",
  storageBucket: "collegeadmit-auth.appspot.com",
  messagingSenderId: "546056099345",
  appId: "1:546056099345:web:59028f30a68664a95903b0",
  measurementId: "G-8KK9XRX891"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);


// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const user = auth.currentUser;
const provider = new GoogleAuthProvider();

const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;

      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("profilePic", profilePic);
    })
    .catch((error) => {
      console.log(error);
    });
};

function LogoutButton() {
    console.log()
    console.log(auth.currentUser)
    signOut(auth).then(() => {
      console.log("signed Out");
    }).catch((error) => {
      // An error happened.
    })
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      console.log(user.uid)
      // ...
    } 
  });



export { LogoutButton, auth, user, provider, db, storage, signInWithGoogle, signInWithEmailAndPassword, createUserWithEmailAndPassword};