import * as React from 'react';
import { useState, useEffect} from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FormControl, Input } from '@mui/material'; 
import ComboBox from '../Decisions/SchoolInput';
import AcceptanceInput from '../Decisions/AcceptanceInput';
import RejectionInput from '../Decisions/RejectionInput';
import WaitlistInput from '../Decisions/WaitlistInput';
import RaceInput from './RaceInput';
import HooksInput from './HooksInput';
import GenderInput from './GenderInput';
import MajorInput from '../Academics/MajorInput';
import StateInput from './StateInput';
import GpawInput from '../Academics/GpawInput';
import GpauwInput from '../Academics/GpauwInput';
import TestOptionalInput from '../Academics/TestOptionalInput';
import TypeInput from './TypeInput';
import { UserAuth } from '../../context/AuthContext';
import { db } from '../../Firebase/firebase';
import {
  doc,
  getDoc
} from 'firebase/firestore';
import YearInput from './YearInput';


export default function General(props) {
    const { user } = UserAuth();
    






    const handleGenderChange = (newValue) => {
      props.gender(newValue);
    };
    const handleYearChange = (newValue) => {
      props.year(newValue);
    };
    const handleHooksChange = (newValue) => {
      props.hooks(newValue);
    };
    const handleRaceChange = (newValue) => {
      props.race(newValue);
    };
    const handleStateChange = (newValue) => {
      props.state(newValue);
    };
    const handleTypeChange = (newValue) => {
      props.type(newValue);
    };

  return (
    <React.Fragment>
    <FormControl>
      <Typography variant="h6" gutterBottom>
        Background Info
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="Bio"
            name="Short Bio"
            label="Bio"
            multiline
            rows={2}
            onChange={(e) => {
                props.Bio(e.target.value);
              }}
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="nameame"
            name="nameame"
            label="Display Name"
            onChange={(e) => {
                props.name(e.target.value);
              }}
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YearInput year={handleYearChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenderInput gender={handleGenderChange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RaceInput race={handleRaceChange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <HooksInput hooks={handleHooksChange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StateInput state={handleStateChange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TypeInput type={handleTypeChange}/>
        </Grid>
      </Grid>
      </FormControl>
    </React.Fragment>
  );
}