
import React from "react";
import Submit from "./Submit";




const PremiumUserFormPage = () => {
  return (
    <div>
    <Submit/>
    </div>


  );
};

export default PremiumUserFormPage;