import {React} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export default function  TestOptionalInput(props) {
  return (
    <Autocomplete
      fullWidth
      onChange={(event, newValue) => {
        props.testOptional(newValue);
      }}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Standardized Test Scores"
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const options = [{label: 'Did Not Submit Scores', testOptional: false, sat: false, act: false}, {label:"Both SAT and ACT", testOptional: true, sat: true, act:true}, {label: "SAT Only", testOptional: true, sat: true, act: false}, {label: "ACT Only", testOptional: true, sat: false, act: true}];