import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { UserAuth } from '../context/AuthContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { db } from '../Firebase/firebase';
import {
  collection,
  addDoc,
  setDoc,
  doc
} from 'firebase/firestore';
import FirebaseUpload from './FirebaseImage';
import General from './AboutMe/Index';
import Advisor from './Advisor/Index';
import Academics from './Academics/Index';
import Decisions from './Decisions/Index';
import { SchoolArray } from './schools';
import { useNavigate } from 'react-router-dom';
import EssayPopUp from './Essays/index';
import ActivityPopUp from './Activities'
import AwardPopUp from './Awards';

function Copyright() { 
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Nerd Apply LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Submit() {
  const { user } = UserAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [newFirstName, setNewFirstName] = useState('');
  const [major, setMajor] = useState([]);
  const [gpaw, setGPAW] = useState('');
  const [race, setRace] = useState('');
  const [gender, setGender] = useState('');
  const [hooks, setHooks] = useState('');
  const [type, setType] = useState('');
  const [gpauw, setGPAUW] = useState('');
  const [school, setSchool] = useState('');
  const [year, setYear] = useState('');
  const [SAT, setSAT] = useState(false);
  const [testOptional, setTestOptional] = useState();
  const [ACT, setACT] = useState(false);
  const [bio, setBio] = useState('');
  const [state, setState] = useState('');
  const [image, setImage] = useState('');
  const [acceptances, setAcceptances] = useState();
  const [rejections, setRejections] = useState();
  const [waitlist, setWaitlist] = useState();
  const [advisor, setAdvisor] = useState('');
  const [show, setShow] = useState('');
  const [advisorName, setAdvisorName] = useState('');
  const [advisorEmail, setAdvisorEmail] = useState('');
  const [advisorCompany, setAdvisorCompany] = useState('');
  const [formData, setFormData] = useState({ activities: [''], titles: [''], categories: [''], positions: [''] });
  const [awardData, setAwardData] = useState({ levels: [''], titles: [''] });  const [title, setTitle] = useState(['']);
  const [schoolEssay, setSchoolEssay] = useState(['']);
  const [prompts, setPrompts] = useState(['']);
  const [essays, setEssays] = useState(['']);
  const navigate = useNavigate();

  const steps = ['Background Info', 'Academics', 'Decisions', 'Advisor', 'Awards', 'Activities', 'Essays'];


  // Function to create a new user document in the database
  const createUser = async () => {
    const usersCollectionRef = doc(db, "profiles", user.uid);
    try {
      const docRef = await setDoc(usersCollectionRef, {
        Name: newFirstName,
        Bio: bio,
        Major: major,
        Gender: gender,
        Race: race,
        Type: type,
        SAT: SAT,
        ACT: ACT,
        GPAW: gpaw,
        GPAUW: gpauw,
        Residence: state,
        School: school,
        Year: year,
        Waitlist: waitlist[0], //i put the entries into the first index of the object then display the first index
        Rejections: rejections[0],
        Acceptances: acceptances[0],
        ProfileImg: image,
        Hooks: hooks,
        Advisor: advisorName,

      });
      console.log(docRef);


      // Create sub-collection for advisor information
      // const AdvisorSubCollectionRef = collection(doc(db, 'profiles', usersCollectionRef.id), 'Advisor');
      // let AdvisorSubDocRef;
      //   // Add advisor information if provided
      //   console.log(advisorName);
      //   AdvisorSubDocRef = await addDoc(AdvisorSubCollectionRef, {
      //     Advisor: advisor,
      //     Name: advisorName,
      //     Email: advisorEmail,
      //     Company: advisorCompany,
      //   });
    

      console.log('Document with ID:', usersCollectionRef.id, 'and sub-document with ID: created successfully!');
    } catch (error) {
      console.error('Error adding document:', error);
    }
  };

  // Function to create the activities sub-collection for a user
  const createActivity = async (id) => {
    const activitiesData = formData.activities.map((activity, index) => ({
      activity: activity,
      title: formData.titles[index],
      category: formData.categories[index],
      position: formData.positions[index],
    }));

    const activitiesCollectionRef = collection(doc(db, 'profiles', id), 'Activities');
    for (let i = 0; i < activitiesData.length; i++) {
      await addDoc(activitiesCollectionRef, activitiesData[i]);
    }
  };

  // Function to create the awards sub-collection for a user
  const createAward = async (id) => {
    const awardsData = awardData.levels.map((level, index) => ({
      title: awardData.titles[index],
      level: level,
    }));

    const awardsCollectionRef = collection(doc(db, 'profiles', id), 'Awards');
    for (let i = 0; i < awardsData.length; i++) {
      await addDoc(awardsCollectionRef, awardsData[i]);
    }
  };

  // Function to upload images for colleges
  const uploadImages = async () => {
    for (let i = 0; i < 60; i++) {
      try {
        console.log(SchoolArray[i] + ' num:' + i);
        const docRef = await setDoc(doc(db, 'CollegeImages', SchoolArray[i]), { Name: SchoolArray[i], Img: `https://firebasestorage.googleapis.com/v0/b/collegeadmit-auth.appspot.com/o/CollegeLogos%2F${SchoolArray[i]}.png?alt=media&token=fa90be7f-193e-4fa7-8b22-ce9260a9823c` });
      } catch (error) {
        console.error('Error adding document:', error);
      }
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <div><FirebaseUpload ProfileImg={setImage} /><General name={setNewFirstName} Bio={setBio}   state={setState} gender={setGender} race={setRace} hooks={setHooks} type={setType} year={setYear}/></div>;
      case 1:
        return <Academics major={setMajor} gpaw={setGPAW} gpauw={setGPAUW} testOptional={setTestOptional} sat={setSAT} act={setACT}  />;
      case 2:
        return <Decisions rejections={setRejections} waitlist={setWaitlist} acceptances={setAcceptances} school={setSchool} />;
      case 3:
        return <Advisor AdvisorName={setAdvisorName} AdvisorEmail={setAdvisorEmail} AdvisorCompany={setAdvisorCompany} advisor={setAdvisor} />;
      case 4:
        return <AwardPopUp awardData={setAwardData} />;
      case 5:
        return <ActivityPopUp formData={setFormData} />;
      case 6:
        return <EssayPopUp prompts={setPrompts} schools={setSchoolEssay} essays={setEssays}/>;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      // Handle step 0
      // Add your code for step 0 logic here
    } else if (activeStep === 1) {
      // Handle step 1
      // Add your code for step 1 logic here
    } else if (activeStep === 2) {
      // Handle step 2
      // Add your code for step 2 logic here
    } else if (activeStep === 3) {
      // Handle step 3
      // Add your code for step 3 logic here
    } else if (activeStep === 4) {
      // Handle step 4
      // Add your code for step 4 logic here
    } else if (activeStep === 5) {
      // Handle step 5
      // Add your code for step 5 logic here
    } else if (activeStep === 6) {
      // Handle step 6
      // Add your code for step 6 logic here
    }
  
    setActiveStep(activeStep + 1);
  };


  const makeUser = () => {
    try {
      createUser();
    } catch (error) {
      console.error('Error adding document:', error);
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container component="main" maxWidth="md" sx={{ mb: 8 }} >
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            {/* <Button onClick={uploadImages}>upload</Button> */}
            <Typography component="h1" variant="h4" align="center">
              Application Info
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, display: { xs: 'none', sm: 'flex' } }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, display: { xs: 'flex', sm: 'none' } }}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for submitting your profile!
                </Typography>
                <Typography variant="subtitle1">
                  Preview your profile here. You can edit your profile any time in the user profile section.
                </Typography>
                <Button onClick={() => navigate("/premiumprofiles/"+user.uid)} >Preview</Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={activeStep === steps.length - 1 ? makeUser : handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? 'Submit Profile' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Paper>
          <Copyright />
        </Container>
      </ThemeProvider>
    </div>
  );
}
