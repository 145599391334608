/*
Banner image with text over it

typewriter functionality with typewriter effect
*/

import React from "react";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import Typewriter from "typewriter-effect";
import Grid from '@mui/material/Unstable_Grid2';
import "./Home.css";
import { Link } from "react-router-dom";




const Banner = () => {
  return (
    <div>
    <div className="home-container">
      <Grid container spacing={0} className="banner-items">
      <Grid xs={12}>
          <div className="Message">
        <Typography variant="h3" className="banner-text">
         Read College
          <Typewriter onInit={(typewriter) => {
            typewriter.typeString("Essays").pauseFor(1000).deleteAll().pauseFor(2000).typeString("Activity List").pauseFor(1000).deleteAll().start();
          }}/>
        </Typography>
        <Button variant="contained" color="primary" >
        <Link to="/Profiles" style={{textDecoration: "none", color: "white"}}>
          Profiles
        </Link>
        </Button>
        <Button className={"button2"} variant="outlined" color="primary" >
          Learn More
        </Button> 
        </div>
        </Grid>
        {/*Right side of banner that displays the students */}
        <Grid xs={4}>

        {/* <MCard/> */}
        {/* <Slideshow className="slide"/> */}

        </Grid>
        
      </Grid>
   
        
     
      
     
      
    </div>
    </div>


  );
};

export default Banner;