import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  getAuth
} from 'firebase/auth';
import { auth, db} from '../Firebase/firebase';
import {
  collection,
  doc,
  setDoc,
  addDoc,
  getDoc,
  query,
  onSnapshot,
  where
} from "firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [docData, setDocData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [stripeData, setStripeData] = useState({});
  const authenticated = getAuth();
  const userinfo = authenticated.currentuser

  const createUserDoc = async (userCredential, name) => {
    console.log("working");
    try {
      const docRef = doc(db, "Users", userCredential.user.uid);
      await setDoc(docRef, {
        unlocks: 10,
        essayUnlocks: 1,
        displayName: name
      });
      // const docSnap = await getDoc(docRef);
      // const data = docSnap.data();
      // setDocData(data);
      // console.log(data.unlocks);
      // console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const getDocData = async (currentUser) => {
    try {
      const docSnap = await getDoc(doc(db, "Users", currentUser.uid));
      const data = docSnap.data();
      setDocData(data);
      return data;
    } catch (error) {
      return null;
      console.error("Error in AuthContext func getDocData: ", error);
    }
  };

  const getProfileData = async (currentUser) => {
    try {
      const docRef = doc(db, "profiles", currentUser.uid);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const data = docSnap.data();
        setProfileData(data);
        return data;
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const getpremiumData = async (id) => {
    try {
      const docSnap = await getDoc(doc(db, "profiles", id));
      const data = docSnap.data();
      return data;
    } catch (error) {
      return null;
      console.error("Error in AuthContext func getDocData: ", error);
    }
  };

  //allows you to get the role of the cutomer 
  const stripeDataListener = async (id) => {
        const docSnap = await doc(db, "customers", id);
        const customerSub =collection(docSnap, "subscriptions");
        const q = query(customerSub, where("status", "in", ["trialing", "active"]))
        onSnapshot(q, (snap) => {
          if (snap.empty) {
            setStripeData(null);
          }
            const subsc = snap.docs[0].data();
            setStripeData(subsc);
        });
  }



  const createUserDoc1 = async (userCredential, name) => {
    try {
      console.log(userCredential.user.uid)
      const docRef = await addDoc(doc(db, "Users", userCredential.user.uid), {
        unlocks: 10,
        essayUnlocks: 1,
        displayName: name
      })
      console.log("docref")
      console.log(docRef)
      console.log(docRef.data.unlocks)
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
  

  const createUser = async (email, password, name) => {
    return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      createUserDoc(userCredential, name);
    })
    .catch((error) => {
      console.log(error.message)
    });
    ;
  };

   const signIn = (email, password) =>  {
    return signInWithEmailAndPassword(auth, email, password)
   }

  const logout = () => {
      return signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      getDocData(currentUser);
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      getDocData(currentUser);
      getProfileData(currentUser);
      setUser(currentUser);
      stripeDataListener(currentUser.uid)
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ createUser, stripeData, user, logout, signIn, docData, getDocData, getProfileData, createUserDoc, profileData, getpremiumData }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};