import React, { useState, useEffect } from 'react';
import { Button, Typography, Dialog, DialogTitle, Box, DialogContent, TextField, DialogActions, Grid } from '@mui/material';
import { UserAuth } from '../context/AuthContext';
import { doc, updateDoc, getDoc } from '@firebase/firestore';
import { db } from '../Firebase/firebase';
import PersonalInfo from './PersonalInfo';
import Academics from '../PremiumProfile/Academics';
import BackgroundStats from './BackgroundStats';
import GenderInput from '../Edit Form/AboutMe/GenderInput';
import RaceInput from '../Edit Form/AboutMe/RaceInput';
import HooksInput from '../Edit Form/AboutMe/HooksInput';
import TypeInput from '../Edit Form/AboutMe/TypeInput';
import StateInput from '../Edit Form/AboutMe/StateInput';
import GpauwInput from '../Edit Form/Academics/GpauwInput';
import GpawInput from '../Edit Form/Academics/GpawInput';
import YearInput from '../Edit Form/AboutMe/YearInput';
import FirebaseUpload from '../Edit Form/FirebaseImage';

const BackgroundSection = () => {
  const { user } = UserAuth();
  const [data, setData] = useState({
    Name: '',
    Bio: '',
    Gender: '',
    Race: '',
    Hooks: '',
    School: '',
    Year: '',
    Type: '',
    Residence: '',
    GPAUW: '',
    GPAW: '',
    ProfileImg: ''
  });
  const [profileEditOpen, setProfileEditOpen] = useState(false);

  const fetchData = async () => {
    try {
      const docRef = doc(db, "profiles", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  // Handle profile edit open
  const handleProfileEditOpen = () => {
    setProfileEditOpen(true);
  };

  const handleProfileEditClose = () => {
    setProfileEditOpen(false);
  };

  // Handle profile save
  const handleProfileSave = async () => {
    if (!user) return;

    try {
      const userRef = doc(db, 'profiles', user.uid);
      await updateDoc(userRef, data);

      console.log('Profile information updated successfully');
      handleProfileEditClose();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value
    }));
  };

  return (
    <div>
      <Grid container spacing={{ xs: 2, md: 2 }}>
        <Grid item xs={12} md={12}>
          <PersonalInfo img={data.ProfileImg} name={data.Name} bio={data.Bio} />
        </Grid>
        <Grid item xs={12} md={12} sx={{ alignItems: 'center', textAlign: "center" }}>
          <Typography className="Title">Background</Typography>
          <BackgroundStats gpaw={data.GPAW} gpauw={data.GPAUW} sat={data.SAT} act={data.ACT} race={data.Race} gender={data.Gender} state={data.Residence} type={data.Type} hooks={data.Hooks} year={data.Year} />
          <Button onClick={handleProfileEditOpen} sx={{ margin: '0 auto' }}>
            Edit Background
          </Button>
        </Grid>
        {/* <Grid item xs={12} md={6} sx={{ alignItems: 'center', textAlign: "center"}}>
            <Typography className="Title">Academics</Typography> 
            <Academics gpaw={data.profileGpaw} gpauw={data.profileGpauw} sat={data.profileSAT} act={data.profileACT}/>
            <Button onClick={handleProfileEditOpen}  sx={{ margin: '0 auto' }} >
                Edit Academics
              </Button>
            </Grid> */}
      </Grid>
      <Dialog open={profileEditOpen} onClose={handleProfileEditClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <Grid container spacing={{ xs: 2, md: 2 }}>
            <Grid item xs={12} md={12}>
              <FirebaseUpload ProfileImg={(value) => handleChange('ProfileImg', value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                autoFocus
                margin="dense"
                id="profileName"
                label="New Name"
                type="text"
                fullWidth
                value={data.Name}
                onChange={(e) => handleChange('Name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                margin="dense"
                id="profileBio"
                label="New Bio"
                type="text"
                fullWidth
                multiline
                rows={2}
                value={data.Bio}
                onChange={(e) => handleChange('Bio', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RaceInput race={(value) => handleChange('Race', value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <TypeInput type={(value) => handleChange('Type', value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <GenderInput gender={(value) => handleChange('Gender', value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <StateInput state={(value) => handleChange('Residence', value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <HooksInput hooks={(value) => handleChange('Hooks', value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <YearInput year={(value) => handleChange('Year', value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <GpauwInput GpaUW={(value) => handleChange('GPAUW', value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <GpawInput GpaW={(value) => handleChange('GPAW', value)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProfileEditClose}>
            Cancel
          </Button>
          <Button onClick={handleProfileSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BackgroundSection;
