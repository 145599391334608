import {React} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { jsondata } from '../schools';


export default function GenderInput(props) {
  return (
    <Autocomplete
      fullWidth
      onChange={(event, newValue) => {
        props.gender(newValue);
      }}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Gender"
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const options = ["Male", "Female", "Other", "Prefer Not To Say"];