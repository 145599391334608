import React, { useState, useEffect } from 'react';
import { Button, Typography, Dialog, DialogTitle, DialogContent, TextField, Grid, DialogActions, Autocomplete, Collapse, IconButton, Paper } from '@mui/material';
import { UserAuth } from '../../context/AuthContext';
import { doc, getDoc, collection, addDoc,  getDocs, updateDoc, deleteDoc } from '@firebase/firestore';
import { db } from '../../Firebase/firebase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SchoolArray } from '../../Edit Form/schools';

const EssayPopUp = () => {
  const { user } = UserAuth();
  const [profileEditOpen, setProfileEditOpen] = useState(false);
  const [school, setSchool] = useState();
  const [prompt, setPrompt] = useState();
  const [essay, setEssay] = useState();
  const [type, setType] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const [expanded, setExpanded] = useState([]);
  const [deleteButtonVisible, setDeleteButtonVisible] = useState(false);
  const [formData, setFormData] = useState({
    schools: [],
    prompts: [],
    essays: [],
    types: []
  });

  // Toggle expanded state for each essay
  const handleExpand = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  useEffect(() => {
    // Fetch data for the user's essays
    const fetchData = async () => {
      try {
        const docRef = collection(doc(db, "profiles", user.uid), "Essays");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setFormData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [user]);

  // Handle profile edit open
  const editEssay = (index) => {
    setSchool(formData.schools[index]);
    setEssay(formData.essays[index]);
    setPrompt(formData.prompts[index]);
    setType(formData.types[index]);
    setCurrentIndex(index);
    setProfileEditOpen(true);
    setDeleteButtonVisible(true);
  };

  useEffect(() => {
    // Fetch sub-collection data for the user's essays
    const subCollectionData = async () => {
      const studentDataRef = collection(doc(db, "profiles", user.uid), "Essays");

      const schools = [];
      const prompts = [];
      const essays = [];
      const types = [];

      try {
        const response = await getDocs(studentDataRef);
        response.docs.forEach((doc) => {
          const data = doc.data(); // Retrieve the data from the document
          schools.push(data.School);
          prompts.push(data.Prompt);
          essays.push(data.Essay);
          types.push(data.Type);
        });

        setFormData({ types, schools, prompts, essays });
        console.log(formData.prompts[1]);
      } catch (error) {
        console.log(error.message);
      }
    };

    subCollectionData();
  }, [user.uid]);

  // Handle profile edit open
  const handleProfileEditOpen = () => {
    setProfileEditOpen(true);
  };

  const handleUploadNew = () => {
    setProfileEditOpen(true);
    setDeleteButtonVisible(false);
    setSchool();
    setEssay();
    setPrompt();
    setType();

  };

  const handleProfileEditClose = () => {
    setProfileEditOpen(false);
  };

  // Handle profile save
  const handleProfileSave = async () => {
    if (!user) return;
  
    try {
      const userRef = collection(doc(db, "profiles", user.uid), "Essays");
      const newEssay = {
        Type: type,
        School: school,
        Prompt: prompt,
        Essay: essay,
      };
      await addDoc(userRef, newEssay);
      setProfileEditOpen(false);
      console.log('Profile information updated successfully');
  
      // Update the form data to include the new essay
      setFormData((prevState) => ({
        schools: [...prevState.schools, newEssay.School],
        prompts: [...prevState.prompts, newEssay.Prompt],
        essays: [...prevState.essays, newEssay.Essay],
        types: [...prevState.types, newEssay.Type]

      }));
    } catch (e) {
      console.log(e.message);
    }
  };
  

  // Handle profile save
  const handleUpdateSave = async () => {
    if (!user) return;

    try {
      const userRef = collection(doc(db, "profiles", user.uid), "Essays");
      const querySnapshot = await getDocs(userRef); 
      const docId = querySnapshot.docs[currentIndex].id;
      const essayRef = doc(userRef, docId);
          await updateDoc(essayRef, 
            {
              Type: type,
              School: school,
              Prompt: prompt,
              Essay: essay,
            }
            );
      setProfileEditOpen(false);
      console.log('Profile information updated successfully');
    } catch (e) {
      console.log(e.message);
    }
  };

  // Handle profile delete
  const handleDelete = async () => {
    if (!user) return;
  
    try {
      const userRef = collection(doc(db, "profiles", user.uid), "Essays");
      const querySnapshot = await getDocs(userRef); 
      const docId = querySnapshot.docs[currentIndex].id;
      await deleteDoc(doc(userRef, docId)); // Delete the document
      setProfileEditOpen(false);
      console.log('Profile information deleted successfully');
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} justifyContent="center" align="center">
          <Typography variant="h2">Essay</Typography>
          <Button onClick={handleUploadNew} className='border px-6 py-2 my-4'>
            Upload New Essay
          </Button>
        </Grid>
        <Grid item xs={12}>
          {formData.essays.map((essay, index) => (
            <Grid item xs={12} key={index}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Grid container spacing={2} alignItems="center">
                <Grid item>
                      <Typography variant="body1">{index + 1}.</Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1" fontWeight="bold" >{formData.schools[index]}</Typography>
                    </Grid>

                    <Grid item>
                      <IconButton
                        onClick={() => handleExpand(index)}
                        aria-expanded={expanded[index]}
                        aria-label="expand"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="flex-end">
                        <Button onClick={() => editEssay(index)}>Edit</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <div>
                    <Typography variant="body1" fontWeight="bold">{formData.prompts[index]}</Typography>
                  </div>
                  <Typography variant="body1">{essay}</Typography>
                </Collapse>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Dialog open={profileEditOpen} onClose={handleProfileEditClose} PaperProps={{ style: { minWidth: '70%' } }}>
        <DialogTitle>Upload Essay</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                 onChange={(event, newValue) => {
                  setType(newValue)
                }}
                value={type}
                id="controllable-states-demo"
                options={["Main Essay", "Supplemental", "UC App"]}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                 onChange={(event, newValue) => {
                  setSchool(newValue)
                }}
                value={school}
                id="controllable-states-demo"
                options={SchoolArray}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="Prompt"
                name="Prompt"
                label="Prompt"
                fullWidth
                multiline
                rows={2}
                value={prompt}
                onChange={(e) => {
                  setPrompt(e.target.value)
                }}
                autoComplete="given-name"
                placeholder="Please enter the prompt and word count of this essay."
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Essay"
                value={essay}
                onChange={(e) => {
                  setEssay(e.target.value)
                }}
                placeholder="Copy and paste your full essay in this box, feel free to remove any information you do not want to be shared by replacing it with [Redacted Content]. "
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProfileEditClose}>
            Cancel
          </Button>
          {!deleteButtonVisible && (
          <Button onClick={handleProfileSave}>
            Save
          </Button>
          )}
          {deleteButtonVisible && (
          <Button onClick={handleUpdateSave}>
            Update
          </Button>
           )}
          {deleteButtonVisible && (
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EssayPopUp;
