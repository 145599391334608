import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UserAuth } from '../../context/AuthContext';
import {states} from './States';
const options = states;

export default function StateInput(props) {
  const { profileData } = UserAuth();
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (profileData) {
        setValue(profileData.Residence);
        props.state(profileData.Residence);
      }
    };
    fetchData();
  }, [profileData]);


  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.state(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        fullWidth
        renderInput={(params) => <TextField {...params} label="State/Region" />}
      />
    </div>
  );
}