import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UserAuth } from '../../context/AuthContext';

const options = ["None", "Legacy", "First Generation", "Recruited Athlete", "Low-Income"];

export default function HooksInput(props) {
  const { profileData } = UserAuth();
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (profileData) {
        setValue(profileData.Hooks);
        props.hooks(profileData.Hooks);
      }
    };

    fetchData();
  }, [profileData]);


  

  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          props.hooks(newValue);
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Hooks" />}
      />
    </div>
  );
}