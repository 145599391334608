import {React, useState, useEffect, Fragment} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FormControl, Autocomplete} from '@mui/material'; 
import AdvisorInput from './AdvisorInput';
import AdvisorReccomendInput from './AdvisorReccomendInput';

export default function Advisor(props) {
    const [advisor, setAdvisor] = useState()


    
  return (
    <FormControl>
      <Typography variant="h6" gutterBottom>
        College Consultant/Advisor
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h7" gutterBottom>
        Did you use a college consultant or advisor?
      </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
        <Autocomplete
              fullWidth
              onChange={(event, newValue) => {
                console.log(newValue);
                setAdvisor(newValue);
                props.advisor(newValue);
              }}
              id="controllable-states-demo"
              options= {["Yes", "No", "Prefer not to say"]}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Advisor/Consultant?"
                />
              )}
            />
        </Grid> 
        <Grid item xs={12} sm={12}>
        <Typography variant="h7" gutterBottom>
        Advisor/Consultant info (leave questions that you cannot answer blank)
      </Typography>
        <TextField
          id="nameame"
          name="nameame"
          label="Advisor/Consultant Name"
          onChange={(e) => {
              props.AdvisorName(e.target.value);
            }}
          fullWidth
          autoComplete="given-name"
          variant="standard"
        />
        </Grid>
        <Grid item xs={12} sm={12}>
        <TextField
          id="Email"
          name="Eamil"
          label="Advisor/Consultant Email"
          onChange={(e) => {
              props.AdvisorEmail(e.target.value);
            }}
          fullWidth
          autoComplete="email"
          variant="standard"
        />
        </Grid>
      
        <Grid item xs={12} sm={12}>
        <TextField
          id="Consulting Company"
          name="Company"
          label="Advisor/Consultant Company"
          onChange={(e) => {
              props.AdvisorCompany(e.target.value);
            }}
          fullWidth
          autoComplete="company"
          variant="standard"
        />
        </Grid>
      </Grid>
      </FormControl>
  );
}