import {React, useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { jsondata } from '../schools';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AdvisorInput(props) {
    const [value, setValue] = useState();


  return (
    <Autocomplete
    value={value}
    fullWidth
    onChange={(event, newValue) => {
        setValue(newValue);
      props.advisor(newValue.advisor);
      props.show(newValue.show)
    }}

    id="controllable-states-demo"
    options={options}
    renderInput={(params) => <TextField {...params} label="Advisor/Consultant?" />

    }
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const options = [{label: "Yes", advisor: true, show: true},{label:"Yes, but I don't want to list them", advisor: true, show: false}, {label: 'No', advisor: false, show: false}];