import { Button, Typography, Dialog, DialogTitle, Box, DialogContent, TextField, DialogActions, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { doc, updateDoc, getDoc } from '@firebase/firestore';
import { db } from '../Firebase/firebase';
import PersonalInfo from './PersonalInfo';
import Academics from '../PremiumProfile/Academics';
import BackgroundStats from './BackgroundStats';
import GenderInput from '../Premium Form/AboutMe/GenderInput';
import RaceInput from '../Premium Form/AboutMe/RaceInput';
import HooksInput from '../Premium Form/AboutMe/HooksInput';
import TypeInput from '../Premium Form/AboutMe/TypeInput';
import AcceptanceInput from '../Edit Form/Decisions/AcceptanceInput';
import RejectionInput from '../Edit Form/Decisions/RejectionInput';
import WaitlistInput from '../Edit Form/Decisions/WaitlistInput';

const Decisions = () => {
  const { user } = UserAuth();

  // Variables for updating the doc
  const [decisions, setDecisions] = useState({
    acceptances: [],
    waitlist: [],
    rejections: [],
  });

  // Variables for setting the data
  const [accepted, setAccepted] = useState([]);
  const [waitlisted, setWaitlisted] = useState([]);
  const [rejected, setRejected] = useState([]);

  const [data, setData] = useState('');
  const [profileEditOpen, setProfileEditOpen] = useState(false);
  const [profileName, setProfileName] = useState();
  const [profileBio, setProfileBio] = useState();
  const [profileGender, setProfileGender] = useState();
  const [profileRace, setProfileRace] = useState();
  const [profileHooks, setProfileHooks] = useState();
  const [profileSchool, setProfileSchool] = useState();
  const [profileType, setProfileType] = useState();

  const fetchCollegesByDecisions = async (decisionsField, stateSetter) => {
    try {
      const studentDoc = doc(db, 'profiles', user.uid);
      const docSnap = await getDoc(studentDoc);
      if (docSnap.exists()) {
        const studentData = docSnap.data();
        if (studentData[decisionsField]) {
          const collegePromises = studentData[decisionsField].map(async (collegeId) => {
            const collegeRef = doc(db, 'CollegeImages', collegeId);
            console.log(collegeId);
            const collegeDoc = await getDoc(collegeRef);
            return collegeDoc.exists() ? collegeDoc.data() : null;
          });
          const collegesData = await Promise.all(collegePromises);
          console.log(`${decisionsField}: `, collegesData);
          stateSetter(collegesData);
        } else {
          console.log(`'${decisionsField}' field does not exist or is not an array.`);
        }
      } else {
        console.log(`No document found in 'profiles' collection with ID: ${user.uid}`);
      }
    } catch (error) {
      console.error(`An error occurred while fetching colleges for '${decisionsField}': `, error);
    }
  };

  useEffect(() => {
    fetchCollegesByDecisions('Acceptances', setAccepted);
    fetchCollegesByDecisions('Waitlist', setWaitlisted);
    fetchCollegesByDecisions('Rejections', setRejected);
  }, [user.uid, db]);

  const fetchData = async () => {
    try {
      const docRef = doc(db, 'profiles', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
        setData(docSnap.data());
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  // Handle profile edit open
  const handleProfileEditOpen = () => {
    setProfileEditOpen(true);
    setProfileName(data.Name);
    setProfileBio(data.Bio);
    setProfileGender(data.Gender);
    setProfileRace(data.Race);
    setProfileHooks(data.Hooks);
    setProfileType(data.Type);
    setProfileSchool(data.School);
  };

  const handleProfileEditClose = () => {
    setProfileEditOpen(false);
  };

  // Handle profile save (still needs to prefill decisions with data so that it can be saved without being changed)
  const handleProfileSave = async () => {

    if (!user) return;


    try {
      const userRef = doc(db, 'profiles', user.uid);
      await updateDoc(userRef, {
        Waitlist: decisions.waitlist[0], // I put the entries into the first index of the object then display the first index
        Rejections: decisions.rejections[0],
        Acceptances: decisions.acceptances[0],
      });

      console.log('Profile information updated successfully');
      handleProfileEditClose();
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <Box>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <h1>Accepted</h1>
            <div>{accepted.map((college) => college.Name).join(', ')}</div>

            {accepted.map((college, key) => (
              <div className="accepted tooltip" key={key}>
                <img src={college.Img} className="school-img" alt={college.Name} />
                <span className="tooltiptext">{college.Name}</span>
              </div>
            ))}
          </Grid>

          <Grid item xs={12}>
            <h1>Waitlisted</h1>
            <div>{waitlisted.map((college) => college.Name).join(', ')}</div>

            {waitlisted.map((college, key) => (
              <div className="waitlisted tooltip" key={key}>
                <img src={college.Img} className="school-img" alt={college.Name} />
                <span className="tooltiptext">{college.Name}</span>
              </div>
            ))}
          </Grid>

          <Grid item xs={12}>
            <h1>Rejected</h1>
            <div>{rejected.map((college) => college.Name).join(', ')}</div>

            {rejected.map((college, key) => (
              <div className="rejected tooltip" key={key}>
                <img src={college.Img} className="school-img" alt={college.Name} />
                <span className="tooltiptext">{college.Name}</span>
              </div>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Button onClick={handleProfileEditOpen} sx={{ margin: '0 auto' }}>
        Edit Academics
      </Button>

      <Dialog open={profileEditOpen} onClose={handleProfileEditClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <Grid container spacing={{ xs: 2, md: 2 }}>
            <Grid item xs={12} md={12}>
              <AcceptanceInput acceptances={(value) => setDecisions({ ...decisions, acceptances: value })} />
            </Grid>
            <Grid item xs={12} md={12}>
              <RejectionInput rejections={(value) => setDecisions({ ...decisions, rejections: value })} />
            </Grid>
            <Grid item xs={12} md={12}>
              <WaitlistInput waitlist={(value) => setDecisions({ ...decisions, waitlist: value })} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProfileEditClose}>Cancel</Button>
          <Button onClick={handleProfileSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Decisions;
