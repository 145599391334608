import {React} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export default function  GpauwInput(props) {
  return (
    <Autocomplete
      fullWidth
      onChange={(event, newValue) => {
        props.GpaUW(newValue);
      }}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="GPA (Unweighted)"
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const options = [{label: "4.0+", value: 4.0}, {label: "3.9+", value: 3.9}, {label: "3.8+", value: 3.8}, {label: "3.7+", value: 3.7}, {label: "3.5+", value: 3.5}, {label: '3.0+', value: 2.0}, {label:"2.0+", value: 2.0}];