import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Typography, Select, MenuItem, InputLabel, Autocomplete } from "@mui/material";


export default function AwardForm(props) {
  const [awardData, setAwardData] = useState({
    titles: [],
    levels: [],
  });
  useEffect(() => {
    addAward();
  }, []);

  const addAward = () => {
    setAwardData({
      ...awardData,
      titles: [...awardData.titles, ""],
      levels: [...awardData.levels, ""],
    });

  };

  const deleteAward = (index) => {
    const updatedData = { ...awardData };
    updatedData.titles.splice(index, 1);
    updatedData.levels.splice(index, 1);
    setAwardData(updatedData);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target || {};
    const updatedData = { ...awardData };
    updatedData[name] = updatedData[name] || [];
    updatedData[name][index] = value;
    setAwardData(updatedData);
    props.awardData(updatedData);
  };

  return (
    <div>
      {/* Loop over award array */}
      {awardData.levels.map((level, index) => (
        <Grid container spacing={1} key={index}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Award {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={["School", "State", "Region", "National"]}
              value={awardData.levels[index] || ""}
              onChange={(event, value) =>
                handleInputChange(
                  {
                    target: {
                      name: "levels",
                      value: value || ""
                    }
                  },
                  index
                )
              }
              renderInput={(params) => (
                <TextField {...params} label="Recognition Level" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="title"
              name="titles"
              label="Award Title"
              fullWidth
              value={awardData.titles[index]}
              onChange={(event) => handleInputChange(event, index)}
              autoComplete="given-name"
              variant="standard"
            />
          </Grid>
        </Grid>
      ))}
      <Button variant="contained" onClick={addAward}>
        Add Award
      </Button>
      <Button variant="contained" onClick={deleteAward}>
        Delete Award
      </Button>
    </div>
  );
}
