import React, { useState, useEffect } from 'react';
import { Button, Typography, Dialog, DialogTitle, DialogContent, TextField, Grid, DialogActions, Autocomplete, Collapse, IconButton, Paper } from '@mui/material';
import { UserAuth } from '../context/AuthContext';
import { doc, getDoc, collection, addDoc,  getDocs, updateDoc, deleteDoc } from '@firebase/firestore';
import { db } from '../Firebase/firebase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SchoolArray } from '../Edit Form/schools';

const ActivityPopUp = () => {
  const { user } = UserAuth();
  const [profileEditOpen, setProfileEditOpen] = useState(false);
  const [activity, setActivity] = useState();
  const [title, setTitle] = useState();
  const [category, setCategory] = useState();
  const [position, setPosition] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const [expanded, setExpanded] = useState([]);
  const [deleteButtonVisible, setDeleteButtonVisible] = useState(false);
  const [formData, setFormData] = useState({
    activities: [],
    titles: [],
    categories: [],
    positions: []
  });

  // Toggle expanded state for each essay
  const handleExpand = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  useEffect(() => {
    // Fetch data for the user's essays
    const fetchData = async () => {
      try {
        const docRef = collection(doc(db, "profiles", user.uid), "Activities");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setFormData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [user]);

  // Handle profile edit open
  const editActivity = (index) => {
    setActivity(formData.activities[index]);
    setTitle(formData.titles[index]);
    setCategory(formData.categories[index]);
    setPosition(formData.positions[index]);
    setCurrentIndex(index);
    setProfileEditOpen(true);
    setDeleteButtonVisible(true); // Show the delete button
  };

  useEffect(() => {
    // Fetch sub-collection data for the user's essays
    const subCollectionData = async () => {
      const studentDataRef = collection(doc(db, "profiles", user.uid), "Activities");

      const  activities = [];
      const  titles = [];
      const  categories = [];
      const   positions = [];

      try {
        const response = await getDocs(studentDataRef);
        response.docs.forEach((doc) => {
          const data = doc.data(); // Retrieve the data from the document
          activities.push(data.activity);
          titles.push(data.title);
          categories.push(data.category);
          positions.push(data.Position);
        });

        setFormData({ activities, titles, categories, positions });
        console.log(formData.prompts[1]);
      } catch (error) {
        console.log(error.message);
      }
    };

    subCollectionData();
  }, [user.uid]);

  // Handle profile edit open
  const handleProfileEditOpen = () => {
    setProfileEditOpen(true);
  };

  // Handle profile edit open
  const handleUploadNew = () => {
    setProfileEditOpen(true);
    setDeleteButtonVisible(false); // Hide the delete button
    setActivity();
    setTitle();
    setCategory();
    setPosition();
  };

  const handleProfileEditClose = () => {
    setProfileEditOpen(false);
  };

  // Handle profile save
  const handleProfileSave = async () => {
    if (!user) return;
  
    try {
      const userRef = collection(doc(db, "profiles", user.uid), "Activities");
      const newActivity = {
        activity: activity,
        title: title,
        category: category,
        Position: position,
      };
      await addDoc(userRef, newActivity);
      setProfileEditOpen(false);
      console.log('Profile information updated successfully');
  
      // Update the form data to include the new activity
      setFormData((prevState) => ({
        activities: [...prevState.activities, newActivity.activity],
        titles: [...prevState.titles, newActivity.title],
        categories: [...prevState.categories, newActivity.category],
        positions: [...prevState.positions, newActivity.Position],
      }));
    } catch (e) {
      console.log(e.message);
    }
  };
  

  // Handle profile save
  const handleUpdateSave = async () => {
    if (!user) return;

    try {
      const userRef = collection(doc(db, "profiles", user.uid), "Activities");
      const querySnapshot = await getDocs(userRef); 
      const docId = querySnapshot.docs[currentIndex].id;
      const essayRef = doc(userRef, docId);
          await updateDoc(essayRef, 
            {
              activity: activity,
              title: title,
              category: category,
              Position: position,
            }
            );
      setProfileEditOpen(false);
      console.log('Profile information updated successfully');
    } catch (e) {
      console.log(e.message);
    }
  };

  // Handle profile delete
  const handleDelete = async () => {
    if (!user) return;
  
    try {
      const userRef = collection(doc(db, "profiles", user.uid), "Activities");
      const querySnapshot = await getDocs(userRef); 
      const docId = querySnapshot.docs[currentIndex].id;
      await deleteDoc(doc(userRef, docId)); // Delete the document
      setProfileEditOpen(false);
      console.log('Profile information deleted successfully');
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="center"  
        sx={{
        minWidth: '70%',
        width: { xs: '100%', sm: '70%' }, // 100% on phone, 70% on computer
        margin: '0 auto', // Center horizontally
      }}>
        <Grid item xs={12} justifyContent="center" align="center">
          <Typography variant="h2">Activities</Typography>
          <Button onClick={handleUploadNew} className='border px-6 py-2 my-4'>
            Upload New Activity
          </Button>
        </Grid>
        <Grid item xs={12}>
          {formData.activities.map((activity, index) => (
            <Grid item xs={12} key={index}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Grid container spacing={2} alignItems="center">
                <Grid item>
                      <Typography variant="body1" fontWeight="bold" >{index + 1}.</Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1" fontWeight="bold">{formData.titles[index]}</Typography>
                    </Grid>

                    <Grid item>
                      <IconButton
                        onClick={() => handleExpand(index)}
                        aria-expanded={expanded[index]}
                        aria-label="expand"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="flex-end">
                        <Button onClick={() => editActivity(index)}>Edit</Button>

                      </Grid>
                    </Grid>
                  </Grid>
                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <div>
                    <Typography variant="body1">{activity}</Typography>
                  </div>
                </Collapse>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Dialog open={profileEditOpen} onClose={handleProfileEditClose} PaperProps={{ style: { minWidth: '70%' } }}>
        <DialogTitle>Upload Activity</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
          <Grid item xs={6}>
              <Autocomplete
                onChange={(event, newValue) => {
                  setCategory(newValue)
                }}
                value={category}
                id="controllable-states-demo"
                options={[
                  'Academic',
                  'Art',
                  'Athletics: Club',
                  'Athletics: JV/Varsity',
                  'Career Oriented',
                  'Community Service (Volunteer)',
                  'Computer/Technology',
                  'Cultural',
                  'Dance',
                  'Debate/Speech',
                  'Environment',
                  'Family Responsibilities',
                  'Foreign Exchange',
                  'Foreign Language',
                  'Internship',
                  'Journalism/Publication',
                  'Junior R.O.T.C.',
                  'LGBT',
                  'Music: Instrumental',
                  'Music: Vocal',
                  'Religious',
                  'Research',
                  'Robotics',
                  'School Spirit',
                  'Science/Math',
                  'Social Justice',
                  'Student Government/Politics',
                  'Theater/Drama',
                  'Work (Paid)',
                  'Other Club/Activity'
                ]}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    {option}
                  </li>
                )}
                renderInput={(params) => ( 
                  <TextField
                    {...params}
                    label="Activity Type"
                  />
                )}
              />
            </Grid>     
            <Grid item xs={12}>
              <TextField
                required
                id="position"
                name="Position"
                label="Position/Leadership description"
                fullWidth
                value={position}
                onChange={(e) => {
                  setPosition(e.target.value)
                }}
                autoComplete="given-name"
                placeholder="Please enter your position. (Max Characters: 50)"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="title"
                name="Organization Name"
                label="Organization Name"
                fullWidth
                multiline
                rows={2}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
                autoComplete="given-name"
                placeholder="Please enter the Organization Name of this activit. (Max Characters: 100)"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                rows={2}
                label="Activity Description"
                value={activity}
                onChange={(e) => {
                  setActivity(e.target.value)
                }}
                placeholder="Please describe this activity, including what you accomplished and any recognition you received, etc. (Max characters: 150)"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProfileEditClose}>
            Cancel
          </Button>
          {!deleteButtonVisible && (
          <Button onClick={handleProfileSave}>
            Save
          </Button>
           )}
          {deleteButtonVisible && (
          <Button onClick={handleUpdateSave}>
            Update
          </Button>
           )}
          {deleteButtonVisible && (
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActivityPopUp;
