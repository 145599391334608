import {React, useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SchoolArray } from '../schools';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AcceptanceInput(props) {
  const [acceptances, setAcceptances] = useState([]);

  const handleOnChange = (event, newValue) => {
      let list=[];
      list.push(newValue);
      list.reduce((result, item, index) => {
        result[index] = item;
        return result;
      }, {});
      console.log(list);
      props.acceptances(list);
  };
  

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={top100Films}
      disableCloseOnSelect
      onChange={handleOnChange}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      fullWidth
      renderInput={(params) => (
        <TextField {...params} label="Acceptances" placeholder="Acceptances" />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = SchoolArray;