/*
Home Page that imports each section from seperate components


*/

import React from 'react'
import Banner from './Banner'
import Slideshow from './Slideshow'
import TestimonialSection from './TestimonialSection'

function Home() {
    return (
    <div>
      <Banner/>


    </div>
    )
}

export default Home;
