import {React, useState, useEffect} from 'react';
import { collection, getDocs, doc, runTransaction, snapshot } from 'firebase/firestore';
import { db } from '../Firebase/firebase';
import { Typography, Divider, Box } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from '../context/AuthContext';
import { Button, Card, CardContent, Select, MenuItem } from '@mui/material';
import PersonalInfo from './PersonalInfo';
import Collapse from '@mui/material/Collapse';
import "./Index.css"
import Academics from './Academics';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Background from './Background';
import ActvitiesSection from './ActvitiesSection';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
// import EssaySection from './EssaySection';
import ActivityPopUp from '../UserAccount/Activity';
import EssayPopUp from '../UserAccount/Essay';
import AwardPopUp from '../UserAccount/Awards';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

    //subcollection test

function SubCollectionpremium(props) {
    const [profile, setProfile] = useState();
    const [profileEssay, setProfileEssay] = useState();
    const [profileInfo, setProfileInfo] = useState();
    const [expanded, setExpanded] = useState(false);
    const [essayExpanded, setEssayExpanded] = useState(false);
    const [showActivities, setShowActivities] = useState(false);
    const [loading, setLoading] = useState(true);
    const { user, docData, getDocData, getpremiumData} = UserAuth();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        console.log(id);
        SubCollectionData();
        EssaySubCollectionData();
        //using authcontext function to get data from any profile id I pass in, returns data i need to write on screen
        getpremiumData(id).then((result) => {
            setProfileInfo(result); // Output: "Success!"
          })
          .catch((error) => {
            console.error(error);
          });
    }, [])

    const unlockProfile = async ()=>{
        if(user){
        console.log(user.uid)
        // Get a reference to the document you want to update
        const docRef = doc(db, "Users", user.uid);

        try {
            const newUnlock = await runTransaction(db, async (transaction) => {
              const userDoc = await transaction.get(docRef);
              if (!userDoc.exists()) {
                throw "Document does not exist!";
              }
          
              const newUnlocks = userDoc.data().essayUnlocks -1;
              if (newUnlocks >= 0) {
                transaction.update(docRef, { essayUnlocks: newUnlocks });
                return newUnlocks;
              } else {
                return Promise.reject("Sorry! You do not have enough unlocks");
              }
            });
            getDocData(user);
            alert("Unlocks decreased to " + newUnlock);
          } catch (e) {
            // This will be a "population is too big" error.
            console.error(e);
          }
        }
        else{
            alert("please log in");
        }

        }

    

        const SubCollectionData = async ()=>{
        const studentDataRef = await collection(doc(db, "profiles", id), "Activities") 
        getDocs(studentDataRef)
        .then(response => {
              const newActivities = response.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              console.log(newActivities);
                setProfile(newActivities);
        })
        .finally(() => {
            setLoading(false);
          })
              .catch(error => console.log(error.message))
          }


          const EssaySubCollectionData = async ()=>{
            const studentDataRef = await collection(doc(db, "profiles", id), "Essays") 
            getDocs(studentDataRef)
            .then(response => {
                  const newEssays = response.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  console.log(newEssays);
                    setProfileEssay(newEssays);
            })
            .finally(() => {
                setLoading(false);
              })
                  .catch(error => console.log(error.message))
              }
          const handleExpandClick = () => {
            setExpanded(!expanded);
          };
          const handleEssayExpandClick = () => {
            setEssayExpanded(!essayExpanded);
          };
  
  
   

  

    if (loading) {
        return <div>
            <Typography variant="h2">Loading Data...</Typography>
            </div>;
      }
    
    return (
        <div >
            <Grid container spacing={{ xs: 2, md: 2 }} sx={{ paddingTop: '10vh' }} className="Profile-container">
            <Grid item xs={12} md={12}>
            <PersonalInfo img={profileInfo.ProfileImg} name={profileInfo.Name} bio={profileInfo.Bio} handleClick={unlockProfile}/>
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography className="Title">Background</Typography> 
            <Background race={profileInfo.Race} gender={profileInfo.Gender} hooks={profileInfo.Hooks}/>
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography className="Title">Academics</Typography> 
            <Academics gpaw={profileInfo.GPAW} gpauw={profileInfo.GPAUW} sat={profileInfo.SAT} act={profileInfo.ACT}/>
            </Grid>

            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <Divider sx={{ width: '100%', my: 4, borderColor: 'grey.500', borderWidth: 3 }} />
            <AwardPopUp/>
          </Box>

        
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center'}}>
            <Divider sx={{ width: '100%', my: 4, borderColor: 'grey.500', borderWidth: 3 }} />
            <ActivityPopUp />
            </Box>

            {/* Activities and Awards Section */}

            
  

          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <Divider sx={{ width: '100%', my: 4, borderColor: 'grey.500', borderWidth: 3 }} />
            <EssayPopUp/>
          </Box>
          
        
          


            {/* {profile[0].data.Activities.map((Activity, index)=> (
              <Grid item xs={12} md={12}>
              <Typography>Activity {index+1}: {Activity}</Typography>
              </Grid>
            ))} */}
            {/* <Grid container spacing={1} className="activities">
              {profile.map((activity) => (
                <Grid item xs={12} md={4.1} key={activity.id}>
                  <ActvitiesSection
                    id={activity.id}
                    title={activity.title}
                    category={activity.category}
                    Position={activity.Position}
                    activity={activity.activity}
                  />
                </Grid>
              ))}
            </Grid> */}
            
         {/* {profile[0].data.Activities.map((Activity, index)=> (
              <Grid item xs={12} md={12}>
              <Typography>Activity {index+1}: {Activity}</Typography>
              </Grid>
            ))} */}
            </Grid>
            {/* <Typography>Actvity 2: </Typography> */}

        </div>
    )
}

export default SubCollectionpremium
