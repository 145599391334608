import { Button, Typography, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { collection, doc, updateDoc, getDoc} from '@firebase/firestore';
import { db } from '../Firebase/firebase';
import BackgroundSection from './Background';
import Account from './Account';
import EssayPopUp from './Essay';
import ActivityPopUp from './Activity';
import AwardPopUp from './Awards';
import Banner from '../Premium Form/Banner';
import Decisions from './Decisions';
 

const UserProfile = () => {
  const { user, logout, docData, stripeData } = UserAuth();
  const navigate = useNavigate();

  const handleView = () => {
    navigate('/premiumprofiles/'+user.uid);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message);
    }
  };


  return (
    <div>
    {/* <Banner/> */}
    <BackgroundSection/>
    <Decisions/>    
     <ActivityPopUp/>
     <AwardPopUp/>
     <EssayPopUp/>
     
     
      <Button onClick={handleLogout} className='border px-6 py-2 my-4'>
        Logout
      </Button>

    
      <Button onClick={handleView} className='border px-6 py-2 my-4'>
        view profile
      </Button>
    
    </div>
  );
};

export default UserProfile;
