import * as React from 'react';
import { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { UserAuth } from './context/AuthContext';
import EssayModal from './Modals/EssayModal';
import UnlockModal from './Modals/UnlockModal';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './NerdApplyLogo.png'

const settings = ['Edit Profile', 'Account'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [unlocks, setUnlocks] = React.useState(null);
  const [essayUnlocks, setEssayUnlocks] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [unlockOpen, setUnlockOpen] = React.useState(false);
  const [name, setName] = React.useState(null);
  const navigate = useNavigate();
  const { user, logout, docData } = UserAuth();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnlockOpen = () => {
    setUnlockOpen(true);
  };

  const handleUnlockClose = () => {
    setUnlockOpen(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    setName(user.displayName);
  }, []);

  useEffect(() => {
    setUnlocks(docData.unlocks);
  }, [docData]);

  useEffect(() => {
    setEssayUnlocks(docData.essayUnlocks);
  }, [docData]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: 'white', zIndex: '999', height: '8vh' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link to="/" style={{ textDecoration: 'none' }}>
                <img
                  src={Logo}
                  alt="NERD APPLY"
                  style={{ height: '7vh', cursor: 'pointer' }}
                />
              </Link>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Box
              sx={{
                flexGrow: 0.008,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {/* Conditional rendering for the user name */}
              {user ? (
                <Typography color="black" variant="h6">Hello, {docData.displayName}!</Typography>
              ) : (
                <>
                  <Button variant="contained" onClick={() => navigate('/signup')}>
                    Register
                  </Button>
                  <Button color="secondary" variant="contained" onClick={() => navigate('/login')}>
                    Log in
                  </Button>
                </>
              )}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">
                      <Button onClick={() => navigate('/' + setting)}>{setting}</Button>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
          <EssayModal open={open} onClose={handleClose} />
          <UnlockModal open={unlockOpen} onClose={handleUnlockClose} />
        </Container>
      </AppBar>
    </Box>
  );
}

export default ResponsiveAppBar;
