import {React, useState, useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SchoolArray } from '../schools';
import { UserAuth } from '../../context/AuthContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function WaitlistInput(props) {
  const [value, setValue] = useState([]);
  const { profileData } = UserAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (profileData && profileData.Waitlist && profileData.Waitlist.length > 0) {
        setValue(profileData.Waitlist);
        let list=[];
        list.push(profileData.Waitlist);
        list.reduce((result, item, index) => {
          result[index] = item;
          return result;
        }, {});
        props.waitlist(list);
      }
    };
  
    fetchData();
  }, [profileData]);



  const handleOnChange = (event, newValue) => {
    let list=[];
      list.push(newValue);
      list.reduce((result, item, index) => {
        result[index] = item;
        return result;
      }, {});
    setValue(newValue);
    props.waitlist(list);
};
  

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={top100Films}
      disableCloseOnSelect
      onChange={handleOnChange}
      value={value}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      fullWidth
      renderInput={(params) => (
        <TextField {...params} label="Waitlists" placeholder="Waitlists" />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = SchoolArray;