export const popularMajors = [
    "Accounting",
    "Aerospace Engineering",
    "African American Studies",
    "Agricultural Business and Management",
    "Agricultural Engineering",
    "Agricultural Sciences",
    "Animal Sciences",
    "Anthropology",
    "Archaeology",
    "Architecture",
    "Art History",
    "Asian Studies",
    "Astronomy",
    "Atmospheric Sciences and Meteorology",
    "Biochemistry",
    "Biology",
    "Biomedical Engineering",
    "Business Administration",
    "Chemical Engineering",
    "Chemistry",
    "Civil Engineering",
    "Classics",
    "Cognitive Science",
    "Communication",
    "Computer Engineering",
    "Computer Science",
    "Construction Management",
    "Criminal Justice",
    "Dance",
    "Dental Hygiene",
    "Dentistry",
    "Early Childhood Education",
    "Ecology",
    "Economics",
    "Education",
    "Electrical Engineering",
    "Elementary Education",
    "Engineering",
    "English",
    "Environmental Science",
    "Environmental Studies",
    "Exercise Science",
    "Fashion Merchandising",
    "Film and Television Studies",
    "Finance",
    "Food Science",
    "Foreign Languages and Literatures",
    "Forensic Science",
    "Forestry",
    "Genetics",
    "Geography",
    "Geology",
    "Graphic Design",
    "Health Science",
    "History",
    "Hospitality and Tourism Management",
    "Human Resources",
    "Human Services",
    "Industrial Design",
    "Industrial Engineering",
    "Information Science",
    "Information Technology",
    "Interior Design",
    "International Business",
    "Journalism",
    "Landscape Architecture",
    "Law",
    "Liberal Arts",
    "Library Science",
    "Linguistics",
    "Management Information Systems",
    "Marketing",
    "Materials Science",
    "Mathematics",
    "Mechanical Engineering",
    "Medical Technology",
    "Medicine",
    "Meteorology",
    "Middle Eastern Studies",
    "Music",
    "Natural Resources",
    "Neuroscience",
    "Nursing",
    "Nutrition",
    "Occupational Therapy",
    "Oceanography",
    "Operations Management",
    "Optometry",
    "Organizational Behavior",
    "Parks, Recreation and Leisure Studies",
    "Pharmacy",
    "Philosophy",
    "Physical Education",
    "Physical Therapy",
    "Physics",
    "Political Science",
    "Psychology",
    "Public Health",
    "Public Relations",
    "Radiologic Technology",
    "Real Estate",
    "Religious Studies",
    "Social Work",
    "Sociology",
    "Software Engineering",
    "Special Education",
    "Speech-Language Pathology",
    "Sports Management",
    "Statistics",
    "Theater",
    "Therapeutic Recreation",
    "Urban Planning",
    "Veterinary Medicine",
    "Visual Arts",
    "Wildlife Biology",
    "Women's Studies",
    "Zoology"
  ];