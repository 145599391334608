import React from 'react'
import { useEffect } from 'react';
import { Avatar } from '@mui/material';
import { useState } from "react";
import { storage } from '../Firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserAuth } from '../context/AuthContext';

function FirebaseUpload(props) {
    const { profileData } = UserAuth();
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState(profileData.ProfileImg);
  
    const handleImageChange = (e) => {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = file["type"];
        const validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];
        if (validImageTypes.includes(fileType)) {
            setImage(file);
            handleSubmit(file);
        } else {
            console.log("Invalid file type. Please select an image file.");
        }
      }
    };
  
    const handleSubmit = (file) => {
      const imageRef = ref(storage, file.name);
      uploadBytes(imageRef, file)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setUrl(url);
              props.ProfileImg(url)
            })
            .catch((error) => {
              console.log(error.message, "error getting the image url");
            });
          setImage(null);
        })
        .catch((error) => {
          console.log(error.message);
        });
    };

    useEffect(() => {
      const fetchData = async () => {
        if (profileData) {
          props.ProfileImg(profileData.ProfileImg);
          setUrl(profileData.ProfileImg);
        }
      };
    
      if (profileData) {
        console.log(profileData);
        fetchData();
      }
    }, [profileData]);
  
    
    return (
        <div className="App">
          <Avatar src={url} sx={{ width: 150, height: 150 }} />
          <input type="file" onChange={handleImageChange} />
        </div>
    )
}
export default FirebaseUpload