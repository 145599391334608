import * as React from 'react';
import { useState, useEffect} from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FormControl, Container} from '@mui/material'; 
import MajorInput from './MajorInput';
import GpawInput from './GpawInput';
import GpauwInput from './GpauwInput';
import TestOptionalInput from './TestOptionalInput';

export default function Academics(props) {
    const [major, setMajor] = useState("");
    const [testOptional, setTestOptional] = useState(true);
    const [sat, setSat] = useState();
    const [act, setAct] = useState();
    const [gpaw, setGPAW] = useState("");
    const [gpauw, setGPAUW] = useState("");

    const handleGpauwChange = (newValue) => {
      props.gpauw(newValue.value);
    };
    const handleGpawChange = (newValue) => {
      props.gpaw(newValue.value);
    };
    const handleTestOptionalChange = (newValue) => {
      props.testOptional(newValue.testOptional);
      setSat(newValue.sat);
      setAct(newValue.act);
      props.sat(newValue.sat);
      props.act(newValue.act);
    };

    useEffect(() => {
      props.major(major);
    }, [major])
    useEffect(() => {
      props.gpaw(gpaw.value);
    }, [gpaw])

    useEffect(() => {
      props.testOptional(testOptional);
      props.sat(sat);
      props.act(act);
    }, [testOptional])
    
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Background Info
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <MajorInput major={setMajor}/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <GpawInput GpaW={handleGpawChange}/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <GpauwInput GpaUW={handleGpauwChange}/>
        </Grid>
        <Grid item xs={12} sm={12}>
        <TestOptionalInput testOptional={handleTestOptionalChange}/>
        </Grid>
        {testOptional && act ?  
        <Grid item xs={12} sm={6}>
        <TextField
          required
          id="ACT"
          name="ACT"
          label="ACT"
          onChange={(e) => {
              props.act(e.target.value);
            }}
          fullWidth
          autoComplete="ACT"
          variant="standard"
        />
      </Grid>:
      null
        }
        {testOptional && sat?  
        <Grid item xs={12} sm={6}>
        <TextField
          required
          id="SAT"
          name="SAT"
          label="SAT"
          onChange={(e) => {
              props.sat(e.target.value);
            }}
          fullWidth
          autoComplete="SAT"
          variant="standard"
        />
      </Grid> :
      null
        }
      </Grid>
    </React.Fragment>
  );
}





