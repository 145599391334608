import React, { useState, useEffect } from 'react';
import { Button, Typography, Autocomplete} from '@mui/material';
import { TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';
import SchoolInput from './SchoolInput';
import { UserAuth } from '../../context/AuthContext';
import { collection, getDocs, doc, runTransaction, snapshot } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';
import { SchoolArray } from '../schools';

export default function EssayForm(props) {
  const { profileData, user } = UserAuth();
  const [schools, setSchools] = useState(['']);
  const [prompts, setPrompts] = useState(['']);
  const [essays, setEssays] = useState(['']);

  const [formData, setFormData] = useState({
    schools: [],
    prompts: [],
    essays: []
  });

  const handlePromptChange = (index, value) => {
    const newPrompt = [...prompts];
    newPrompt[index] = value;
    setPrompts(newPrompt);
    props.prompts(newPrompt);
  };

  const SubCollectionData = async () => {
    const studentDataRef = collection(doc(db, "profiles", user.uid), "Essays");
  
    const schools = [];
    const prompts = [];
    const essays = [];
  
    getDocs(studentDataRef)
      .then(response => {
        response.docs.forEach((doc) => {
          const data = doc.data(); // Retrieve the data from the document
          schools.push(data.School);
          prompts.push(data.Prompt);
          essays.push(data.Essay);
        });
  
        setFormData({schools, prompts, essays});
        console.log(formData.prompts[1]);
      })
      .catch(error => console.log(error.message));
  };
  useEffect(() => {
    SubCollectionData();
  }, [user.uid]);

  const handleAddEssay = () => {
    setFormData({
      ...formData, 
      schools: [...formData.schools, ""],
      prompts: [...formData.prompts, ""],
      essays: [...formData.essays, ""],
    });
    console.log(formData);
    props.formData(formData);
  };

  const handleEssayChange = (index, value) => {
    const newEssay = [...essays];
    newEssay[index] = value;
    setEssays(newEssay);
    props.essays(newEssay);
  };

  const handleSchoolChange = (index, value) => {
    const newSchool = [...schools];
    newSchool[index] = value;
    setSchools(newSchool);
    props.schools(newSchool);
  };

  const handleInputChange = (name, value, index) => {
    const updatedData = { ...formData };
    updatedData[name] = updatedData[name] || [];
    updatedData[name][index] = value;
    setFormData(updatedData);
    props.formData(updatedData);
  };
  

  const handleAddActivity = () => {
    setEssays([...essays, '']);
  };
  const handleDeleteEssay = (index) => {
    const updatedData = { ...formData };
    updatedData.schools.splice(index, 1);
    updatedData.prompts.splice(index, 1);
    updatedData.essays.splice(index, 1);
    setFormData(updatedData);
    props.formData(updatedData);
  };

  const options = SchoolArray;


  return (
    <>
      
      <React.Fragment>
      <Grid container spacing={1}>
      <Typography variant="h6" gutterBottom>
        Essays
      </Typography>
      {formData.essays.map((essay, index) => (
        <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={12}>
        <Typography variant="h6" >
        Essay {index + 1}
        </Typography>
        </Grid>
          <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            onChange={(event, value) => {
              handleInputChange('schools', value, index)
            }}
            value={formData.schools[index]}
            id="controllable-states-demo"
            options={options}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="School"
              />
            )}
          />
          </Grid>
          <Grid item xs={12} sm={12}>
        <TextField
            required
            id="Prompt"
            name="Prompt"
            label="Prompt"
            fullWidth
            value={formData.prompts[index]}
            onChange={(event) => {
              handleInputChange('prompts', event.target.value, index)
            }}
            autoComplete="given-name"
          />
          </Grid>
          <Grid item xs={12} sm={12}>
        <TextField
          key={index}
          fullWidth
          multiline
          rows={2}
          label={"Essay"}
          value={formData.essays[index]}
          onChange={(event) => {
            handleInputChange('essays', event.target.value, index)
          }}
        />
        </Grid>
        </Grid>

      ))}
        
      </Grid>
    </React.Fragment>
      <Button onClick={handleAddEssay}>Add Essay</Button>
      <Button onClick={handleDeleteEssay}>Delete Essay</Button>
    </>
  );
}